import React from "react";
import Layout from "layout";
import { Link } from "gatsby";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import navSchema from "layout/NavStructure";

import "./sitemap.scss";

const getItemSchema = (_id) => {
  return navSchema.filter((navItem) => navItem.id === _id)[0];
};

const getSubSchema = (_parentId) => {
  return navSchema.filter((navItem) => navItem.parent === _parentId);
};

const NavItem = ({ id, isExternal, className }) => {
  const item = getItemSchema(id);

  return !isExternal ? (
    <Link
      to={item.navLink}
      className={className || ""}
      dangerouslySetInnerHTML={{ __html: item.title }}
    />
  ) : (
    <a
      href={item.navLink}
      className={className || ""}
      target="_blank"
      rel="noreferrer"
      dangerouslySetInnerHTML={{ __html: item.title }}
    />
  );
};

const SubNavItems = ({ parentId }) => {
  const parentSchema = getItemSchema(parentId);
  const childrenSchema = getSubSchema(parentId);
  return (
    <div>
      <NavItem id={parentSchema.id} className={"menu-level-2"} />
      {childrenSchema.map((item) => (
        <NavItem id={item.id} className={"menu-level-3"} />
      ))}
    </div>
  );
};

function SiteMapPage({ pageContext }) {
  return (
    <Layout pageClass="page-sitemap" breadcrumbData={pageContext.breadcrumb}>
      <Seo
        pageTitle="Sitemap"
        pageDescription="Navigate through the site here"
      />

      <div className="inner-body-content">
        <section className="inner-centered-container sitemap-links">
          <Row>
            <Col sm={12}>
              <h1>Site Map</h1>
            </Col>
          </Row>

          <Row className="menu-block sun">
            <Col sm={12}>
              <NavItem id="face" className={"menu-level-1"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navSkinAgingFactor"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navImmersiveHydrationTechnology"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navEucerinFaceProducts"} />
            </Col>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>

          <Row className="menu-block">
            <Col sm={12}>
              <NavItem id="navBody" className={"menu-level-1"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navDrySkin"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navBodyEczema"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navDamagedSkin"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navItch"} />
            </Col>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>

          <Row className="menu-block">
            <Col sm={12}>
              <NavItem id="navBaby" className={"menu-level-1"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navGeneralCare"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navBabyEczema"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navDiaperRash"} />
            </Col>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>

          <Row className="menu-block sun">
            <Col sm={12}>
              <NavItem id="navSun" className={"menu-level-1"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navImpactsOfSunlight"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navFreeRadicalDefense"} />
            </Col>
            <Col sm={4}>
              <SubNavItems parentId={"navEucerinSunProducts"} />
            </Col>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>

          <Row className="menu-block">
            <Col sm={4}>
              <NavItem id="navAbout" className={"menu-level-1"} />
            </Col>
            <Col sm={4}>
              <NavItem id="navResources" className={"menu-level-1"} />
            </Col>
            <Col sm={4}>
              <a
                href="https://bdf.hcpsampling.com"
                className={"menu-level-1"}
                target="_blank"
                rel="noreferrer"
              >
                Request Samples
              </a>
            </Col>
            {/*
            <Col sm={4}>
              <a
                href="https://www.eucerinus.com/meta-pages/contact"
                className={"menu-level-1"}
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </Col> */}
          </Row>
        </section>
      </div>
    </Layout>
  );
}

export default SiteMapPage;
